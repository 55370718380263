<template>
  <div
    v-loading="!isLoaded"
    class="market-activity-tab"
  >
    <section
      v-if="isLoaded"
      class="market-activity-table-container"
    >
      <h3>Market activity</h3>
      <table>
        <thead>
          <tr>
            <th
              v-for="key in Object.keys(tableData)"
              :key="key"
              :class="key"
              :data-test="`${key} column header`"
              v-text="$options.filters.capitalize(key)"
            />
          </tr>
        </thead>
        <tbody data-test="market activity table">
          <tr
            v-for="(row, index) in totalRows"
            :key="index"
          >
            <td>{{ tableData.submitted[index] }}</td>
            <td>{{ tableData.pending[index] }}</td>
            <td>{{ tableData.declined[index] }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'pinia';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductTableStore } from '@/stores/productTable.js';
  import ServiceDocument from '@/services/documents.js';
  import RfpOverview from '@/mixins/rfpOverview.js';

  /**
   * RFP Overview Market Activity Tab
   *
   * @exports RfpOverview/MarketActivity
   */
  export default {
    name: 'MarketActivity',
    mixins: [RfpOverview],
    data: () => ({
      isLoaded: false,
      tableData: {
        submitted: [],
        pending: [],
        declined: [],
      },
    }),
    computed: {
      ...mapState(useProjectStore, [
        'brokerHierarchy',
        'documents',
        'inforceDocument',
      ]),
      ...mapState(useProductTableStore, ['productTableData']),

      /**
       * Evaluates the tableData data lengths to determine what the max number is.
       *
       * @returns {number}
       */
      totalRows() {
        return Math.max(this.tableData.submitted.length, this.tableData.pending.length, this.tableData.declined.length);
      },
    },
    watch: {
      'brokerHierarchy.hasHierarchy': {
        handler(hasHierarchy) {
          if (hasHierarchy !== undefined && !this.hideMarketTab) {
            this.isLoaded = true;
          }
        },
        immediate: true,
      },
    },
    async created() {
      const getFullDocuments = this.documents.map((document) => ServiceDocument.getDocumentInfo(document.id));

      await Promise.all(getFullDocuments)
        .then((documents) => {
          const inforceCarriers = [];

          documents.forEach(async ({ document }) => {
            if (document.document_type.toLowerCase() !== 'policy') {
              return;
            }
            inforceCarriers.push(...new Set(document.products.map(
              (product) => product.project_product.inforce_carrier_id,
            )));
          });

          documents.forEach(async ({ document }) => {
            if (document.document_type.toLowerCase() !== 'proposal') {
              return;
            }
            const status = this.projectStatusFromProducts(document.products);
            let carrierName = document.carrier.name;

            if (inforceCarriers.includes(document.carrier.id)) {
              carrierName += ' (in-force)';
            }

            // Possible Statuses: Not Started, Incomplete, Selected, Declined, Submitted
            if (['Selected', 'Submitted'].includes(status.badge)) {
              this.tableData.submitted.push(carrierName);
            } else if (status.badge === 'Declined') {
              this.tableData.declined.push(carrierName);
            } else {
              this.tableData.pending.push(carrierName);
            }
          });
        });
    },
    methods: {
      ...mapActions(useProductTableStore, ['projectStatusFromProducts']),
    },
  };
</script>

<style lang="scss" scoped>
  section {
    padding: 0 20px;
  }

  h3 {
    margin-bottom: 23px;
  }

  table {
    width: 100%;
    font-size: 14px;
  }

  th,
  td {
    padding: 14px 20px;
    border: 1px solid $tf-extra-light-gray-1;
  }

  th {
    width: percentage(.3333);
    font-weight: 600;
  }

  .submitted {
    background-color: #e2f8f0;
    border: 1px solid $tf-green;
  }

  .pending {
    background-color: #fff4ea;
    border-color: $tf-orange;
  }

  .declined {
    background-color: #f7cfca;
    border-color: $tf-red;
  }
</style>
